import React from "react";
import ReactCrop from 'react-image-crop'
import 'react-image-crop/dist/ReactCrop.css';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import Icon from '@material-ui/core/Icon';
import { SizeMe } from 'react-sizeme'
import Button from "@material-ui/core/Button";
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import TextField from "@material-ui/core/TextField";

let corsURL = 'http://localhost:4199/api/getImage/';
class ImageUploader extends React.PureComponent {
    state = {
        src: null,
        uploadType: 'local',
        imageLink: '',
        crop: {
        },
    };

    onSelectFile = e => {

        if (e.target.files && e.target.files.length > 0) {
            const reader = new FileReader();
            reader.addEventListener('load', () =>
                this.setState({ src: reader.result })
            );
            reader.readAsDataURL(e.target.files[0]);
        }
    };

    // If you setState the crop in here you should return false.
    onImageLoaded = image => {
        this.imageRef = image;
        this.imageRef.origin = 'anonymous';
    };

onCropComplete = crop => {
    this.makeClientCrop(crop);
};

onCropChange = (crop) => {
    this.setState({ crop });
};

async makeClientCrop(crop) {
    if (this.imageRef && crop.width && crop.height) {
        const croppedImageUrl = await this.getCroppedImg(
            this.imageRef,
            crop,
            'newFile.jpeg'
        );
        this.setState({ croppedImageUrl });
    }
}

getCroppedImg(image, crop, fileName) {
    const canvas = document.createElement('canvas');
    const scaleX = image.naturalWidth / image.width;
    const scaleY = image.naturalHeight / image.height;
    canvas.width = crop.width;
    canvas.height = crop.height;
    const ctx = canvas.getContext('2d');

    ctx.drawImage(
        image,
        crop.x * scaleX,
        crop.y * scaleY,
        crop.width * scaleX,
        crop.height * scaleY,
        0,
        0,
        crop.width,
        crop.height
    );

    return new Promise((resolve, reject) => {
        canvas.toBlob(blob => {
            if (!blob) {
                //reject(new Error('Canvas is empty'));
                console.error('Canvas is empty');
                return;
            }
            blob.name = fileName;
            window.URL.revokeObjectURL(this.fileUrl);
            this.fileUrl = window.URL.createObjectURL(blob);
            resolve(this.fileUrl);
        }, 'image/jpeg');
    });
}

    selectionType = () => {
        return <div style={{margin: 15}}><FormControl variant="outlined" >
            <InputLabel  id="demo-simple-select-outlined-label">
                Source
            </InputLabel>
            <Select
                labelId="demo-simple-select-outlined-label"
                id="demo-simple-select-outlined"
                value={this.state.uploadType}
                onChange={(e) => this.setState({src: null, croppedImageUrl: null, uploadType: e.target.value})}
                labelWidth={100}
            >
                <MenuItem value='local'>Upload</MenuItem>
                <MenuItem value='url'>Link</MenuItem>
            </Select>
        </FormControl></div>
    };

localUpload = () => {
    return  <div className='flex-stuff'>
        <input
            accept="image/*"
            style={{display: 'none'}}
            id="outlined-button-file"
            multiple
            type="file"
            onChange={this.onSelectFile}
        />
        <label htmlFor="outlined-button-file">
            <Button variant="contained" component="span" startIcon={<CloudUploadIcon />}>
                Choose {this.props.buttonName}
            </Button>
        </label>
    </div>;
};

    urlUpload = () => {
        return  <div className='flex-stuff'>
            <form noValidate autoComplete="off">
                <TextField id="outlined-basic"
                           style={{width: 500}}
                           onChange={ev => {
                               this.setState({imageLink: ev.target.value})
                           }}
                           label="URL" variant="outlined" />
                <Button onClick={() => {
                this.setState({src: corsURL+encodeURIComponent(this.state.imageLink)})}
                }>Upload</Button>
            </form>
        </div>;
    };

render() {
    const { crop, src, croppedImageUrl } = this.state;

    return (
        <SizeMe>
            {({size}) => {
                return <div className="App">
                    {this.selectionType()}
                    {croppedImageUrl && (
                        <>
                            <Button
                                style={{marginLeft: 5, marginBottom: 20}}
                                onClick={() => this.props.finishedWithCrop(croppedImageUrl, crop)}
                                variant="contained"
                                color="primary"
                                endIcon={<Icon>send</Icon>}
                            >
                                Next
                            </Button>
                        </>

                    )}
                    {this.state.uploadType === 'local' ? this.localUpload() : this.urlUpload()}
                    { (!this.state.errored && this.state.src && !croppedImageUrl) && <p className={"instruction"}>Click and drag to crop</p>}
                    {src && (
                        <ReactCrop
                            crossorigin='anonymous'
                            src={src}
                            crop={crop}
                            ruleOfThirds
                            imageStyle={{maxWidth: size.width * 0.7, maxHeight: size.height * 0.8 || 800}}
                            onImageLoaded={this.onImageLoaded}
                            onComplete={this.onCropComplete}
                            onChange={this.onCropChange}
                        />
                        // button for next
                    )}
                </div>
            }
            }
        </SizeMe>
    );
}
}

export default ImageUploader
