import React, { Component } from "react";
import PropTypes from "prop-types";
import isEqual from "react-fast-compare";
import SketchField from "../Sketcher/sketch";
import Button from "@material-ui/core/Button";
import Tools from "../Sketcher/tools";
import Paper from "@material-ui/core/Paper";
import measureGif from '../assets/measure-example.gif'
import furtherGif from '../assets/furthermeasurement.gif'

// TODO allow freec omparison mode that pops up dropdowns

export default class Comparator extends Component {
    constructor(props) {
        super(props);

        this.state = {
            drawingProportions: [],
            referenceProportions: [],
            isValid: false,
            leftSketch: [],
            rightSketch: [],
            tool:  Tools.Line,
            width: 500,
            height: 500,

        };


    }

    updateLeftSketch(objs) {
        this.setState({leftSketch: objs})
    }

    updateRightSketch(objs) {
        this.setState({rightSketch: objs})
    }




    // TODO, width/height are based on image size

    render() {
        let firstHelper = "Click and drag the same line across both images. Try and pick the most accurate area, like between the eyes. This line is used to scale the images.";
        let secondHelper = "Feel free to make additional comparison lines, which will show up as green.";
        let isReady = this.state.leftSketch.length >0 && this.state.rightSketch.length > 0;
        return (<>
            <div style={{width: 600,display: 'flex' }}>
                <Paper style={{display: 'flex'}} elevation={1} >
                    <p className={"helper-text"}>{isReady ? secondHelper : firstHelper}</p>
                    <img style={{flexShrink: 0}} src={isReady ? furtherGif : measureGif} />
                    { isReady &&
                    <Button intent="success" text="move forwards" onClick={() => this.props.getReview(extractStuff(this.state))} >Next</Button>
                    }
                </Paper>
            </div>
            <div style={{display: 'flex'}}>
                <div>
                    <Button  onClick={() => this._leftSketch.clear()}  >Clear</Button>
                    <SketchField
                        name="sketch"
                        className="canvas-area"
                        ref={c => (this._leftSketch = c)}
                        lineColor={this.state.leftSketch.length === 0 ? 'gold' : 'green'}
                        lineWidth={this.state.tool === Tools.Line ? 2 : 5}
                        width={this.props.leftImage.dimensions.width}
                        height={this.props.leftImage.dimensions.height}
                        defaultValue={this.state.leftSketchObjs}
                        forceValue
                        imgBackground={this.props.leftImage}
                        updateParentWithObjects={(objs) => this.updateLeftSketch(objs)}
                        tool={Tools.Line}
                    />
                </div>
                <div>
                    <Button onClick={() => this._rightSketch.clear()} >Clear</Button>
                    <SketchField
                        name="sketch"
                        className="canvas-area"
                        ref={c => (this._rightSketch = c)}
                        lineColor={this.state.rightSketch.length === 0 ? 'gold' : 'green'}
                        lineWidth={3}
                        width={this.props.rightImage.dimensions.width}
                        height={this.props.rightImage.dimensions.height}
                        defaultValue={this.state.rightSketchObjs}
                        forceValue
                        imgBackground={this.props.rightImage}
                        updateParentWithObjects={(objs) => this.updateRightSketch(objs)}
                        tool={Tools.Line}
                    />

                </div>

            </div>
            </>
        );
    }
}

function extractStuff(geometries) {
    let {leftSketch, rightSketch} = geometries;
        leftSketch.shift();
        rightSketch.shift();
    let lr = leftSketch[0];
    let rr = rightSketch[0];

    return {
        leftBase: [lr.x1, lr.y1, lr.x2, lr.y2],
        rightBase: [rr.x1, rr.y1, rr.x2, rr.y2],
        leftDrawings: convertToArr(leftSketch),
        rightDrawings: convertToArr(rightSketch)
    }
}

function convertToArr(raw, width, height) {
    return raw.map(g => {
        let coords = [g.x1, g.y1, g.x2, g.y2];
            return { name: "1", shape: "line", coords, preFillColor: "green", fillColor: "blue" };
    })
}
