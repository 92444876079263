import React, { Component } from "react";
import PropTypes from "prop-types";
import isEqual from "react-fast-compare";

export default class DoubleImageMapper extends Component {
    constructor(props) {
        super(props);
        [
            "drawline",
            "initCanvas",
            "renderLines"
        ].forEach(f => (this[f] = this[f].bind(this)));
        let absPos = { position: "absolute", top: 0 };
        this.styles = {
            container: { position: "relative" },
            canvas: { ...absPos, pointerEvents: "none", zIndex: 2 },
            img: { ...absPos, zIndex: 1, userSelect: "none" },
            map: (props.onClick && { cursor: "line" }) || undefined
        };
        // Props watched for changes to trigger update
        this.watchedProps = [
            "active",
            "fillColor",
            "height",
            "imgWidth",
            "lineWidth",
            "src",
            "strokeColor",
            "width",
            "referenceStyle",
            "drawingStyle",
            "offset"
        ];
    }

    shouldComponentUpdate(nextProps) {
        const propChanged = this.watchedProps.some(
            prop => this.props[prop] !== nextProps[prop]
        );
        return propChanged;
    }

    componentWillMount() {
        this.updateCacheMap();
    }

    updateCacheMap() {
        this.setState(
            { drawingMap: JSON.parse(JSON.stringify(this.props.drawingAreas)),
                referenceMap: JSON.parse(JSON.stringify(this.props.referenceAreas))  },
            this.initCanvas
        );
    }

    componentDidUpdate() {
        this.updateCacheMap();
        this.initCanvas();
    }

    drawline(coords, contextKey, color) {
        let [x1, y1, x2, y2] = coords;
        this[contextKey].strokeStyle = color;
        this[contextKey].globalAlpha = this.props.opacity;
        this[contextKey].beginPath();
        this[contextKey].moveTo(x1, y1);
        this[contextKey].lineTo(x2, y2);
        this[contextKey].lineWidth = 5;
        this[contextKey].stroke();
    }


    initCanvas() {
        if (this.props.width) this.img.width = this.props.width;

        if (this.props.height) this.img.height = this.props.height;

        this.drawingCanvas.width = this.props.width || this.img.clientWidth;
        this.drawingCanvas.height = this.props.height || this.img.clientHeight;
        this.referenceCanvas.width =  this.props.width || this.img.clientWidth;
        this.referenceCanvas.height =  this.props.height || this.img.clientHeight;

        this.container.style.width =
            (this.props.width || this.img.clientWidth) + "px";
        this.container.style.height =
            (this.props.height || this.img.clientHeight) + "px";
        this.referenceCtx = this.referenceCanvas.getContext("2d");
        this.drawingCtx = this.drawingCanvas.getContext("2d")
        if (this.props.onLoad) this.props.onLoad();

        this.renderLines();
    }

    scaleCoords(coords, scaleFactor, offset) {
        let { imgWidth, width } = this.props;

        // calculate scale based on current 'width' and the original 'imgWidth'
        let scale = width && imgWidth && imgWidth > 0 ? width / imgWidth : 1;

        if (scaleFactor) {
            scale = scaleFactor
        }
        return coords.map((coord, i) => {
                return coord * scale
        });
    }

    renderLines() {
        const showLines = this.props.showLines;
       if (showLines) {
           this.state.referenceMap.areas.map(area => {
               this.drawline(
                   this.scaleCoords(area.coords),
                   'referenceCtx',
                   '#059c3c'

               );
           });
           this.state.drawingMap.areas.map(area => {
               this.drawline(
                   this.scaleCoords(area.coords, this.props.scaleFactor),
                   'drawingCtx',
                   '#d9a809'
               );
           });
       }
    }

    render() {
        let {top} = this.props.drawingStyle;
        const showImages = this.props.showImages ? {} : {display: 'none'};
        let { referenceStyle, drawingStyle } = this.props;
        if (!this.props.showImages) {
            referenceStyle.opacity=0;
            drawingStyle.opacity=0;
        }
        return (
                <div style={{width: 800, position: 'relative', marginLeft: 50}} ref={node => (this.container = node)}>
                    <img
                    style={referenceStyle}
                    src={this.props.leftImage.url}
                    useMap={`reference`}
                    alt=""
                    ref={node => (this.img = node)}
                    onLoad={this.initCanvas}
                />
                <canvas ref={node => (this.referenceCanvas = node)} style={{...this.styles.canvas, right: 0, top: referenceStyle.top  }} />
                    <img
                    style={drawingStyle}
                    src={this.props.rightImage.url}
                    useMap={`drawing`}
                    alt=""
                    ref={node => (this.img = node)}
                    onLoad={this.initCanvas}
                />
                <canvas ref={node => (this.drawingCanvas = node)} style={{...this.styles.canvas, left: this.props.drawingStyle.left, top }} />
            </div>
        );
    }
}

DoubleImageMapper.defaultProps = {
    active: true,
    fillColor: "rgba(255, 255, 255, 0.5)",
    lineWidth: 1,
    map: {
        areas: [],
        name: "image-map-" + Math.random()
    },
    referenceAreas: {
        areas: [],
        name: "image-map-" + Math.random()
    },
    drawingAreas: {
        areas: [],
        name: "image-map-" + Math.random()
    },
    strokeColor: "rgba(0, 0, 0, 0.5)"
};