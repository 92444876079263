import React from "react";
import UploadSteps from "./components/UploadSteps";
import ShowComparisons from "./components/ShowComparisons";
import Comparator from "./components/Comparator";
import Button from "@material-ui/core/Button";
import logo from './assets/jmplogo.png';
import progressGif from './assets/progress.gif'
import createMuiTheme from "@material-ui/core/styles/createMuiTheme";
import { ThemeProvider } from '@material-ui/styles';
import example1 from './assets/example-previews/example-1.png';
import example2 from './assets/example-previews/example-2.png';
import example3 from './assets/example-previews/example-3.png';
import example4 from './assets/example-previews/example-4.png';
import example5 from './assets/example-previews/example-5.png';
import exampleStates from './components/ExampleStates';

const theme = createMuiTheme({
    palette: {
        primary: {
            main: '#0e638e',
            contrastText: '#fcf3d9',
        },
        secondary: {
            light: '#0066ff',
            main: '#0044ff',
            contrastText: '#ffcc00',
        },
        contrastThreshold: 3,
        tonalOffset: 0.2,
        background: {
            paper: '#fcf3d9'
        }
    },
});

export default class Entry extends React.Component {

    componentDidMount() {
        window.addEventListener('beforeunload', this.beforeunload.bind(this));
    }

    componentWillUnmount() {
        window.removeEventListener('beforeunload', this.beforeunload.bind(this));
    }

    beforeunload(e) {
        if (this.state.hasBeenEdited) {
            e.preventDefault();
            e.returnValue = true;
        }
    }

    constructor(props) {
        super(props);
        this.state = {
            stage: 'start',
            hasBeenEdited: false,
            leftImage: {},
            rightImage: {},
            modalOpen: false
        };
    }

    headerBanner = () => {
        return <div>
            <a href={window.location.href}><img id='logo-padding' src={logo}/></a>
            {/*<IconButton*/}
            {/*onClick={() => {this.setState({modalOpen: true})}}*/}
            {/*><InfoIcon fontSize="small" color="disabled" /></IconButton>*/}
        </div>

    };

    startComp = () => <>
        <div className='flex-stuff'>
        <div style={{width: 300}}>
            <h5>Upload a reference photo and a drawing,
                and we'll autoscale and overlay the images to help you compare proportions</h5>

            <Button color="primary" className={"start-button"}
                    variant="contained" onClick={() => this.setState({stage: 'crop'})} >Start</Button>
        </div>
        <div>
            <img id='logo-padding' src={progressGif}/>
            <p className={"art-attribution"}>Art by <a target="_blank" href="https://www.instagram.com/toni_art96/">Tony Nguyen</a></p>
        </div>
        </div>
        {this.exampleReel()}
        </>;

    getCropped = (croppedStuff) => {
        let { leftImage, rightImage } = croppedStuff;
        this.setState({stage: 'analyze', leftImage, rightImage})
    };

    getReview = (bases) => {
    let { leftBase, rightBase, leftDrawings, rightDrawings} = bases;
    let { leftImage, rightImage } = this.state;
    leftImage.base = leftBase;
    rightImage.base = rightBase;
    leftImage.drawings = leftDrawings;
    rightImage.drawings = rightDrawings;
    this.setState({stage: 'review', leftImage, rightImage, hasBeenEdited: false})
        console.log(JSON.stringify(this.state))
    };

    renderAppropriateComp = () => {
        switch (this.state.stage) {
            case 'start':
                return this.startComp();
            case 'crop':
                return <>
                    <UploadSteps
                        setEdited={() => {this.setState({hasBeenEdited: true})}}
                        getCropped={this.getCropped}/>
                    </>;

            case 'analyze':
                return <Comparator leftImage={this.state.leftImage} rightImage={this.state.rightImage} getReview={this.getReview}/>;
            case 'review':
                return <ShowComparisons leftImage={this.state.leftImage}
                                        rightImage={this.state.rightImage} />;
        }
    };


    exampleReel = () => {
        return (<div className={"example-area"}>
            <div>
                <h3>Examples</h3>
            </div>
            <div className={"example-row"}>
                <figure><img src={example5} className={"example-img"}
                             onClick={() => {
                                 this.setState(exampleStates.mary)
                             }}
                /></figure>
                    <figure><img src={example3} className={"example-img"}
                     onClick={() => {
                         this.setState(exampleStates.skull)
                     }}
                    /></figure>
                <figure ><img src={example1} className={"example-img"}
                              onClick={() => {
                                  this.setState(exampleStates.elep)
                              }}
                /></figure>
                        <figure><img src={example2} className={"example-img"}
                     onClick={() => {
                         this.setState(exampleStates.bridges)
                     }}
                        /></figure>
                            <figure><img src={example4} className={"example-img"}
                     onClick={() => {
                         this.setState(exampleStates.house)
                     }}
                            /></figure>

            </div>
            </div>
        )
    }


    render() {
        return (
            <ThemeProvider theme={theme}>
            <div>
                {this.headerBanner()}
                <div id='main-container'>
             {this.renderAppropriateComp()}
                </div>
            </div>
            </ThemeProvider>
        );
    }
}
