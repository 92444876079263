class FabricCanvasTool {
    constructor(canvas) {
        canvas.hoverCursor = "crosshair";
        canvas.CURSOR = "crosshair";
        canvas.cursor = "crosshair";
        this._canvas = canvas;
    }

    configureCanvas(props) {
        this._canvas.selection = false;
        this._canvas.hoverCursor = "crosshair";
    }

    doMouseUp(event) {

    }

    doMouseDown(event) {

    }

    doMouseMove(event) {

    }

    doMouseOut(event) {

    }
}

export default FabricCanvasTool;