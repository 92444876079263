import React from "react";
import ImageUploader from "./ImageUploader";

export default class UploadSteps extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            drawingImageDimensions: null,
            drawingImageURL: '',
            referenceImageDimensions: null,
            referenceImageURL: null,
            activeImageURL: null,
            isDone: false,
            buttonName: "Reference"
        };
    }

    finishedWithCrop = (imageURL, dimensions) => {
        this.props.setEdited();
        if (this.state.drawingImageURL !== '') {
            this.setState({referenceImageURL: imageURL, referenceImageDimensions: dimensions, isDone: true});
            this.props.getCropped({
                leftImage: {url: this.state.drawingImageURL, dimensions: this.state.drawingImageDimensions},
                rightImage: {url: imageURL, dimensions: dimensions},
            })
        } else {
            this.setState({drawingImageURL: imageURL, drawingImageDimensions: dimensions, buttonName: "Art"})
        }
    };

    render() {
        return (
            <div>
                <ImageUploader buttonName={this.state.buttonName} finishedWithCrop={this.finishedWithCrop} key={this.state.drawingImageURL} />
            </div>
        );
    }
}