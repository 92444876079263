import React from "react";

import DoubleImageMapper from "./DoubleImageMapper";
import Slider from '@material-ui/core/Slider';
import 'rc-slider/assets/index.css';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import Typography from "@material-ui/core/Typography";
import withStyles from "@material-ui/core/styles/withStyles";
import {SizeMe} from "react-sizeme";
import Feedback from "./Feedback";
import Button from "@material-ui/core/Button";

const PrettoSlider = withStyles({
    root: {
        color: '#cb9c1d',
        height: 8,
    },
    thumb: {
        height: 24,
        width: 24,
        backgroundColor: '#fff',
        border: '2px solid currentColor',
        marginTop: -8,
        marginLeft: -12,
        '&:focus,&:hover,&$active': {
            boxShadow: 'inherit',
        },
    },
    active: {},
    valueLabel: {
        left: 'calc(-50% + 4px)',
    },
    track: {
        height: 8,
        borderRadius: 4,
    },
    rail: {
        height: 8,
        borderRadius: 4,
    },
})(Slider);

export default class ShowComparisons extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            comparisonOpacity: 0.5,
            leftImage: {},
            rightImage: {},
            horizontalOffset: 0,
            verticalOffset: 0,
            manualSizing: 0,
            displayType: 'both'
        };
    }

    calculateScale() {
        const {leftImage, rightImage} = this.props;
        let [ x1, y1, x2, y2 ] = leftImage.base;
        const leftScale = Math.sqrt(Math.pow(x2-x1,2) + Math.pow(y2-y1, 2));
        let [ _x1, _y1, _x2, _y2 ] = rightImage.base;
        const rightScale = Math.sqrt(Math.pow(_x2-_x1,2) + Math.pow(_y2-_y1, 2));
        const scaleLeftToRight = leftScale/rightScale;
        const leftX = -(scaleLeftToRight*_x1-x1);
        const leftY = -(scaleLeftToRight*_y1-y1);
        return {
            scale: scaleLeftToRight,
            leftX,
            leftY
        }
        // figure out point difference
    }

    displayTypePicker = () => {

        return <div style={{margin: 15}}><FormControl variant="outlined" >
            <InputLabel  id="demo-simple-select-outlined-label">
                Display
            </InputLabel>
            <Select
                labelId="demo-simple-select-outlined-label"
                id="demo-simple-select-outlined"
                value={this.state.displayType}
                onChange={(e) => this.setState({displayType: e.target.value})}
                labelWidth={100}
            >
                <MenuItem value='both'>Images + Lines</MenuItem>
                <MenuItem value='onlyimages'>Only Images</MenuItem>
                <MenuItem value='onlylines'>Only Lines</MenuItem>
            </Select>
        </FormControl></div>
    }

    showShareLink = () => {
        if (this.state.hasBeenShared === true) {
            return null
        } else {
            return <Button>Share me</Button>
        }
    };


    render() {
        const {leftImage, rightImage } = this.props;
        let scale = this.calculateScale();
        let showLines = ['both', 'onlylines'].includes(this.state.displayType);
        let showImages = ['both', 'onlyimages'].includes(this.state.displayType);
        let top = scale.leftY;
        let absolutetop = 0;
        let vertPadd = leftImage.dimensions.height - rightImage.dimensions.height*scale.scale;
        if (scale.leftY < 0) {
           absolutetop = absolutetop - scale.leftY;
           top = 0;
        }
        let referenceStyle = {
            opacity: 100,
            position: 'relative',
            top: absolutetop,
            width: leftImage.dimensions.width
        };
            let drawingStyle = {
                opacity: this.state.comparisonOpacity,
                position: 'absolute',
                top: top+3*this.state.verticalOffset,
                left: scale.leftX+3*this.state.horizontalOffset,
                width: rightImage.dimensions.width*scale.scale+3*this.state.manualSizing
            };
            return <><SizeMe>
                {(size) =>
                 <div>

                <div style={{display: 'flex'}}>
                    <Button onClick={() => {
                        this.setState({horizontalOffset: 0, verticalOffset: 0, comparisonOpacity:0.5, manualSizing: 0})
                    }
                    }>RESET</Button>
                {this.displayTypePicker()}
                <div style={{width: 100, margin: 15}}>
                    <Typography gutterBottom>Opacity</Typography>
                    <PrettoSlider
                        valueLabelDisplay="auto" aria-label="pretto slider" value={100*this.state.comparisonOpacity}
                        onChange={(e, v) => {
                            this.setState({comparisonOpacity: v/100})
                        }}
                    />
                </div>
                    <div style={{width: 100, margin: 15}}>
                <Typography gutterBottom>H Offset</Typography>
                <PrettoSlider
                    valueLabelDisplay="auto" aria-label="pretto slider" value={this.state.horizontalOffset}
                    min={-(size.width || 1000)/8} max={(size.width || 1000)/8}
                    onChange={(e, v) => {
                        this.setState({horizontalOffset: v})
                    }}
                />
                </div>
                    <div style={{width: 100, margin: 15}}>
                        <Typography gutterBottom>V Offset</Typography>
                        <PrettoSlider
                            min={-(size.width || 1000)/8} max={(size.width || 1000)/8}
                            valueLabelDisplay="auto" aria-label="pretto slider" value={this.state.verticalOffset}
                            onChange={(e, v) => {
                                this.setState({verticalOffset: v})
                            }}
                        />
                    </div>
                    <div style={{width: 100, margin: 15}}>
                        <Typography gutterBottom>Sizing</Typography>
                        <PrettoSlider
                            min={-(size.width || 1000)/8} max={(size.width || 1000)/8}
                            valueLabelDisplay="auto" aria-label="pretto slider" value={this.state.manualSizing}
                            onChange={(e, v) => {
                                this.setState({manualSizing: v})
                            }}
                        />
                    </div>
                </div>
                <DoubleImageMapper {...this.props} scaleFactor={scale.scale} opacity={this.state.comparisonOpacity}
                referenceStyle={referenceStyle} drawingStyle={drawingStyle}
                                   referenceAreas={{
                                       name: "reference",
                                       areas: leftImage.drawings
                                   }}
                                   showLines={showLines} showImages={showImages}
                                   offset={this.state.horizontalOffset/2}
                                   drawingAreas={{
                                       name: "drawing",
                                       areas: rightImage.drawings
                                   }}
                />
            </div>}
                </SizeMe>
                <Feedback vertPadd={vertPadd}/>
                </>
                ;

    }
}
