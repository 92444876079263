import oneRef from '../assets/example-actual/1-actual.jpg'
import oneDraw from '../assets/example-actual/1-draw.jpg'
import twoRef from '../assets/example-actual/2-actual.jpg'
import twoDraw from '../assets/example-actual/2-draw.jpg'
import threeRef from '../assets/example-actual/3-actual.jpg'
import threeDraw from '../assets/example-actual/3-draw.jpg'
import fourRef from '../assets/example-actual/4-actual.jpg'
import fourDraw from '../assets/example-actual/4-draw.jpg'
import fiveRef from '../assets/example-actual/5-actual.jpg'
import fiveDraw from '../assets/example-actual/5-draw.jpg'

var exampleStates = {};

exampleStates.elep = {
    "stage": "review",
    "hasBeenEdited": false,
    "leftImage": {
        "url": oneRef,
        "dimensions": {"x": 8, "y": 4.6999969482421875, "width": 513, "height": 602, "unit": "px"},
        "base": [278, 225.3000030517578, 327, 229.3000030517578],
        "drawings": [{
            "name": "1",
            "shape": "line",
            "coords": [278, 225.3000030517578, 327, 229.3000030517578],
            "preFillColor": "green",
            "fillColor": "blue"
        }]
    },
    "rightImage": {
        "url": oneDraw,
        "dimensions": {"x": 45, "y": 59.69999694824219, "width": 579, "height": 619.3000030517578, "unit": "px"},
        "base": [280, 237.3000030517578, 350, 240.3000030517578],
        "drawings": [{
            "name": "1",
            "shape": "line",
            "coords": [280, 237.3000030517578, 350, 240.3000030517578],
            "preFillColor": "green",
            "fillColor": "blue"
        }]
    },
    "modalOpen": false
};

exampleStates.bridges = {
    "stage": "review",
    "hasBeenEdited": false,
    "leftImage": {
        "url": twoRef,
        "dimensions": {"x": 36, "y": 26.699996948242188, "width": 564, "height": 724.3000030517578, "unit": "px"},
        "base": [252, 303.3000030517578, 366, 300.3000030517578],
        "drawings": [{
            "name": "1",
            "shape": "line",
            "coords": [252, 303.3000030517578, 366, 300.3000030517578],
            "preFillColor": "green",
            "fillColor": "blue"
        }]
    },
    "rightImage": {
        "url": twoDraw,
        "dimensions": {"x": 9, "y": 7.6999969482421875, "width": 669, "height": 670.3000030517578, "unit": "px"},
        "base": [355, 289.3000030517578, 499, 285.3000030517578],
        "drawings": [{
            "name": "1",
            "shape": "line",
            "coords": [355, 289.3000030517578, 499, 285.3000030517578],
            "preFillColor": "green",
            "fillColor": "blue"
        }]
    },
    "modalOpen": false
};

exampleStates.skull = {
    "stage": "review",
    "hasBeenEdited": false,
    "leftImage": {
        "url": threeRef,
        "dimensions": {"x": 36, "y": 6.6999969482421875, "width": 636, "height": 659.3000030517578, "unit": "px"},
        "base": [260, 182.3000030517578, 256, 295.3000030517578],
        "drawings": [{
            "name": "1",
            "shape": "line",
            "coords": [260, 182.3000030517578, 256, 295.3000030517578],
            "preFillColor": "green",
            "fillColor": "blue"
        }, {
            "name": "1",
            "shape": "line",
            "coords": [114, 180.3000030517578, 130, 112.30000305175781],
            "preFillColor": "green",
            "fillColor": "blue"
        }, {
            "name": "1",
            "shape": "line",
            "coords": [168, 568.3000030517578, 376, 516.3000030517578],
            "preFillColor": "green",
            "fillColor": "blue"
        }, {
            "name": "1",
            "shape": "line",
            "coords": [314, 353.3000030517578, 313, 447.3000030517578],
            "preFillColor": "green",
            "fillColor": "blue"
        }, {
            "name": "1",
            "shape": "line",
            "coords": [423, 341.3000030517578, 382, 510.3000030517578],
            "preFillColor": "green",
            "fillColor": "blue"
        }, {
            "name": "1",
            "shape": "line",
            "coords": [513, 100.30000305175781, 557, 183.3000030517578],
            "preFillColor": "green",
            "fillColor": "blue"
        }]
    },
    "rightImage": {
        "url": threeDraw,
        "dimensions": {"x": 137, "y": 56.69999694824219, "width": 443, "height": 556, "unit": "px"},
        "base": [183, 186.3000030517578, 180, 269.3000030517578],
        "drawings": [{
            "name": "1",
            "shape": "line",
            "coords": [183, 186.3000030517578, 180, 269.3000030517578],
            "preFillColor": "green",
            "fillColor": "blue"
        }, {
            "name": "1",
            "shape": "line",
            "coords": [61, 168.3000030517578, 73, 121.30000305175781],
            "preFillColor": "green",
            "fillColor": "blue"
        }, {
            "name": "1",
            "shape": "line",
            "coords": [117, 464.3000030517578, 273, 429.3000030517578],
            "preFillColor": "green",
            "fillColor": "blue"
        }, {
            "name": "1",
            "shape": "line",
            "coords": [314, 292.3000030517578, 288, 412.3000030517578],
            "preFillColor": "green",
            "fillColor": "blue"
        }, {
            "name": "1",
            "shape": "line",
            "coords": [227, 306.3000030517578, 225, 362.3000030517578],
            "preFillColor": "green",
            "fillColor": "blue"
        }, {
            "name": "1",
            "shape": "line",
            "coords": [371, 101.30000305175781, 413, 184.3000030517578],
            "preFillColor": "green",
            "fillColor": "blue"
        }]
    },
    "modalOpen": false
}

exampleStates.house = {
    "stage": "review",
    "hasBeenEdited": false,
    "leftImage": {
        "url": fourRef,
        "dimensions": {"x": 25, "y": 6.6999969482421875, "width": 620, "height": 717, "unit": "px"},
        "base": [206, 156.3000030517578, 204, 284.3000030517578],
        "drawings": [{
            "name": "1",
            "shape": "line",
            "coords": [206, 156.3000030517578, 204, 284.3000030517578],
            "preFillColor": "green",
            "fillColor": "blue"
        }]
    },
    "rightImage": {
        "url": fourDraw,
        "dimensions": {"x": 77, "y": 10.699996948242188, "width": 601, "height": 667.3000030517578, "unit": "px"},
        "base": [175, 119.30000305175781, 173, 252.3000030517578],
        "drawings": [{
            "name": "1",
            "shape": "line",
            "coords": [175, 119.30000305175781, 173, 252.3000030517578],
            "preFillColor": "green",
            "fillColor": "blue"
        }]
    },
    "modalOpen": false
}


exampleStates.mary = {
    "stage": "review",
    "hasBeenEdited": false,
    "leftImage": {
        "url": fiveRef,
        "dimensions": {"x": 20, "y": 47.69999694824219, "width": 574, "height": 752.3000030517578, "unit": "px"},
        "base": [178, 267.3000030517578, 217, 177.3000030517578],
        "drawings": [{
            "name": "1",
            "shape": "line",
            "coords": [178, 267.3000030517578, 217, 177.3000030517578],
            "preFillColor": "green",
            "fillColor": "blue"
        }, {
            "name": "1",
            "shape": "line",
            "coords": [364, 56.30000305175781, 364, 56.30000305175781],
            "preFillColor": "green",
            "fillColor": "blue"
        }]
    },
    "rightImage": {
        "url": fiveDraw,
        "dimensions": {"x": 11, "y": 11.699996948242188, "width": 653, "height": 771, "unit": "px"},
        "base": [171, 280.3000030517578, 220, 193.3000030517578],
        "drawings": [{
            "name": "1",
            "shape": "line",
            "coords": [171, 280.3000030517578, 220, 193.3000030517578],
            "preFillColor": "green",
            "fillColor": "blue"
        }]
    },
    "modalOpen": false
}

export default exampleStates;
