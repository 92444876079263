import * as React from "react";
import Collapsible from "react-collapsible";
import FreeBreakfastIcon from '@material-ui/icons/FreeBreakfast';
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import Icon from "@material-ui/core/Icon";
import RefAngle from '../assets/refangle.png';
import Divider from "@material-ui/core/Divider";



export default class extends React.Component {
    constructor(props) {
        super(props);
        this.state = { feedback: '', isComplete: false, name: 'Name', email: 'email@example.com' };
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    forms = () =>  <form  noValidate autoComplete="off">
        <div style={{padding: 20}}>
            <TextField
                id="outlined-multiline-static"
                label="Feedback"
                multiline
                className={'padded-input'}
                rows="9"
                onChange={(e) => this.handleTextChange(e)}
                defaultValue=""
                variant="outlined"
            />
            <TextField
                id="outlined-multiline-flexible"
                label="Your Email"
                className={'padded-input'}
                rowsMax="1"
                defaultValue=""
                placeHolder={"Your email"}
                onChange={(e) => this.handleEmailChange(e)}
                variant="outlined"
            />
        </div>
        <Button
            variant="contained"
            color="primary"
            className={"email-button"}
            endIcon={<Icon>send</Icon>}
            onClick={this.handleSubmit}
        >
            Send
        </Button>
    </form>;

    kofiComp = () => <a href='https://ko-fi.com/R6R6XKN8' target='_blank'>
        <Button
            style={{marginBottom: 20, marginTop: 20}}
            variant="outlined"
            startIcon={<FreeBreakfastIcon />}
        >Support this site</Button>
    </a>;

    references = () =><>
        <h5 style={{margin: 0}}>Also check out</h5>
        <a href='http://referenceangle.com' target='_blank'>
        <img style={{width: 500}} src={RefAngle}/>
    </a>
    </>

    render() {
        let comp = this.forms();
        if (this.state.isComplete) {
            comp =  <p>Thank you for the feedback!</p>
        }
        let { vertPadd } = this.props;
        return   <div className={"feedback"} style={{paddingTop: 20 + vertPadd}}>
            {this.references()}
            <Divider variant="middle" />
            {this.kofiComp()}
            <Divider variant="middle" />

            <Collapsible trigger="Contact / Feedback">
            {comp}
        </Collapsible>
            </div>
    }

    handleSubmit (event) {
        const templateId = 'jmp';
        this.sendFeedback(templateId, {message_html: this.state.feedback, from_name: this.state.email, reply_to: this.state.email})
    }

    handleEmailChange(event) {
        this.setState({email: event.target.value})
    }

    handleTextChange(event) {
        this.setState({feedback: event.target.value})
    }

    sendFeedback (templateId, variables) {
        window.emailjs.send(
            'gmail', templateId,
            variables
        ).then(res => {
            console.log('Email successfully sent!')
        })
        // Handle errors here however you like, or use a React error boundary
            .catch(err => console.error('Oh well, you failed. Here some thoughts on the error that occured:', err));
        this.setState({isComplete: true})
    }
}
